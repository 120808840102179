<template>
  <v-container id="user-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" class="px-5 py-3">
      <v-card-title>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#1b5e20"
              class="white--text"
              elevation="2"
              v-bind="attrs"
              v-on="on"
              @click="clearForm"
              >Add Licence</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-model="editedItem.store_code"
                        label="Store Code"
                        required
                        :rules="store_codeRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-select
                        v-model="editedItem.memberId"
                        :items="members"
                        attach
                        chipsref="memberReff"
                        label="Select Member"
                        required
                        :rules="memberIdRules"
                        item-text="member"
                        item-value="id"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#1b5e20" class="white--text" elevation="2" @click="close">
                Cancel
              </v-btn>
              <v-btn color="#1b5e20" class="white--text" elevation="2" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <div class="warning text-center">
              <header class="text-h5">Confirmation</header>
            </div>
            <v-card-title class="text-h6"
              >Are you sure, delete this licence store code :{{ editedItem.store_code }}?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#1b5e20"
                class="white--text"
                elevation="2"
                @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="#1b5e20"
                class="white--text"
                elevation="2"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="keyword"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @input="doSearch"
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        ref="vDataTable"
        :headers="headers"
        :items="licences"
        hide-default-footer
        class="elevation-0"
      >
        <template #item="{ item, index, headers }">
          <tr>
            <td>
              {{ index + 1 + (page - 1) * 10 }}
            </td>
            <td>
              {{ item.store_code }}
            </td>
            <td>
              {{ item.member }}
            </td>
            <td>
              {{ item.licence }}
            </td>
            <td>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-center">
        <v-pagination
          v-model="page"
          @input="go"
          :length="lengthPage"
          :total-visible="5"
          color="#1b5e20"
        ></v-pagination>
      </div>
    </base-material-card>
    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
          align-center
          justify-center
        ></v-progress-circular>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      licences: [],
      keyword: "",
      page: 0,
      lengthPage: 0,
      headers: [
        { text: "No", value: "id" },
        { text: "Store Code", value: "store_code" },
        { text: "Member", value: "member" },
        { text: "Licence", value: "licence" },
        { text: "Actions", sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editedItem: {
        id: "",
        store_code: "",
        member: "",
        memberId: "",
      },
      defaultItem: {
        id: "",
        store_code: "",
        member: "",
        memberId: "",
      },
      progressBar: false,
      isDisabled: true,
      members: [
        {
          value: "id",
        },
        {
          value: "member",
        },
      ],
      store_codeRules: [
        (v) => !!v || "Store Code is required",
        (v) => (v && v.length <= 20) || "Max 20 characters",
      ],
      memberIdRules: [(v) => !!v || "Member is required"],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return "New Licence";
    },
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    go() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/licence?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.licences = data;
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    doSearch() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/licence/search/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.licences = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.go();
      }
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.progressBar = true;
      let formData = new FormData();
      formData.set("id", this.editedItem.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/licence/delete-permanent", formData, config)
        .then((response) => {
          let { data } = response;
          this.setAuth(data.data);
          this.setAlert({
            status: true,
            text: data.message,
            color: "success",
          });
          this.closeDelete();
          this.licences.push(this.editedItem);
          this.go();
          this.progressBar = false;
        })
        .catch((error) => {
          let { data } = error;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
          this.licences.push(this.editedItem);
          this.go();
          this.progressBar = false;
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    goMember() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/licence/member", config)
        .then((response) => {
          let { data } = response.data;
          this.members = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        let formData = new FormData();
        formData.set("storeCode", this.editedItem.store_code);
        formData.set("id", this.editedItem.id);
        formData.set("memberId", this.editedItem.memberId);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .post("/licence/store", formData, config)
          .then((response) => {
            let { data } = response;
            this.setAuth(data.data);
            this.setAlert({
              status: true,
              text: data.message,
              color: data.status,
            });
            this.licences.push(this.editedItem);
            this.clear();
          })
          .catch((error) => {
            let { data } = error;
            this.setAlert({
              status: true,
              text: data.message,
              color: "error",
            });
            this.clear();
          });
      }
    },
    clearForm() {
      this.goMember();
      this.$refs.form.reset();
    },
    clear() {
      this.go();
      this.close();
      this.progressBar = false;
      this.clearForm();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.go();
    this.goMember();
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.position-relative {
  position: relative;
}
</style>
